
import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PasswordComponent } from '../password/password.component';
import { ToastrService } from 'ngx-toastr';
import { DownloadFormDialogComponent } from '../download-form-dialog/download-form-dialog.component';
import { CrudService } from 'src/app/core/crud.service';
import { AuthStorage } from 'src/app/core/auth.storage';
import { MatDialog } from '@angular/material/dialog';
import { LocalQuoteSummary } from 'src/app/core/model/local.quote.summary';

@Component({
  selector: 'kt-local-quotes',
  templateUrl: './local-quotes.component.html',
  styleUrls: ['./local-quotes.component.scss']
})
export class LocalQuotesComponent implements OnInit {
	@Input() subgroup;
	customer: any;
	form: FormGroup;
	@ViewChild('wizard', {static: true}) el: ElementRef;
	mainMemberGroup: FormGroup;


	membersGroup: FormGroup;
	mainMemberProduct: any;
	extendedMemberProduct: any;
	mainMemberPremium = 0;
  subGroupObj = null;
	extendedMemberPremium = 0;
	dependentsCount: Array<any> = [];
	supplimentaryBenefits: Array<any> = [];
	readonlyForProduct = false;
  currencyCode = "ZiG";

	planType: any;
	subPlanType: any;
	// dependentsSubPlanType: any;
	// planTypeDependents: any;
	planTypesForDependents : Array<any> = [];
	planTypes: any[] = [
		 {name: 'PLATINUM', position: 4}, {name: 'DIAMOND', position: 3}, {name: 'GOLD', position: 2}, {name: 'SILVER', position: 1},
	];
	totalPremium = 0;
	mainMemberBenefitsAmt = 0;
	extendedMemberBenefitsAmt = 0;
	numOfDependents = 0;
	mainMemberBenefits?: Array<any> = [];
	extendedMemberBenefits?: Array<any> = [];
	disableMMBenefits = false;
	mainMemberBenefit = {easipolBenefitId: 0};
  isProductSelected = false;
  constructor(private fb: FormBuilder, private service: CrudService, private cdr: ChangeDetectorRef, private modalService: NgbModal,
	 private authStorage: AuthStorage, private router: Router, private notification: ToastrService, public dialog: MatDialog) {
	  this.customer = this.authStorage.getUser();
   }

  ngOnInit() {
	this.createForm();
	//this.getSupplimentaryBenefits();
 }
 ngAfterViewInit(): void {

   if(this.subgroup === 'SIXPACK'){
	   this.numOfDependents = 5;
	   this.readonlyForProduct = true;
	   this.dependentsCount = Array(4).fill(0).map((x,i)=>i + 2);
   }
}
 createForm() {
   this.form = this.fb.group({
	   id: new FormControl(),
	   version: new FormControl(),
	   createdById: new FormControl(),
	   dateCreated: new FormControl(new Date()),
	   mainMember: this.createMainMember(),

   });
}
downloadForm(){
	let downloadData = undefined;
	if(this.subgroup === 'SCOREPACK'){
		downloadData = {link: './assets/media/pdfs/Score-Pack-Plan01.pdf', title: 'SCORE PACK FORM'}
	} else {
		downloadData = {link: './assets/media/pdfs/Six-Pack-Plan01.pdf', title: 'SIX PACK FORM'}
	}

 const dialogRef = this.dialog.open(DownloadFormDialogComponent, {
	 data: downloadData
	});

 dialogRef.afterClosed().subscribe(result => {
	  console.log('The dialog was closed');

	});
  }

createMainMember() {
	this.mainMemberGroup = new FormGroup({
		id: new FormControl(this.customer !== null ? this.customer!.id : null) ,
		version: new FormControl(this.customer !== null ?  this.customer!.version : null),
		createdBy: new FormControl(this.customer !== null ?  this.customer!.createdBy : null),
		dateCreated: new FormControl(this.customer !== null ?  this.customer!.dateCreated : null),
		title: new FormControl(this.customer !== null ?  this.customer!.title : null),
		firstName: new FormControl(
			this.customer !== null ?  this.customer!.firstName : null,
			Validators.compose([
				Validators.maxLength(25),
				Validators.minLength(2),
				Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
				Validators.required
			])
		),
		lastName: new FormControl(
			this.customer !== null ?  this.customer!.lastName : null,
			Validators.compose([
				Validators.maxLength(25),
				Validators.minLength(2),
				Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
				Validators.required
			])
		),
		email: new FormControl(this.customer !== null ?  this.customer!.email : null),
		province: new FormControl(this.customer !== null ?  this.customer!.province : null),
		street: new FormControl(this.customer !== null ?  this.customer!.street : null),
		gender: new FormControl(this.customer !== null ?  this.customer!.gender : null),
		mobileNumber: new FormControl(this.customer !== null ?  this.customer!.mobileNumber : null),
		postalAddress: new FormControl(this.customer !== null ?  this.customer!.postalAddress : null),
		customerId: new FormControl(this.customer !== null ?  this.customer!.customerId : null),
		physicalAddress: new FormControl(this.customer !== null ?  this.customer!.physicalAddress : null),
		city: new FormControl(this.customer !== null ?  this.customer!.city : null),
		country: new FormControl('ZIMBABWE'),
		policyNumber: new FormControl(this.customer !== null ?  this.customer!.policyNumber : null),
		memberType: new FormControl('MAIN_MEMBER'),
	});
	return this.mainMemberGroup;
}


get mainMember() { return this.form.get('mainMember'); }
onSubmit(){
		let quoteSummary = new LocalQuoteSummary();


	  quoteSummary.customer = this.form.get('mainMember').value;
  quoteSummary.mainMemberProduct = this.mainMemberProduct;
  quoteSummary.currencyCode = this.currencyCode;

  quoteSummary.customerId = this.customer === null ? null : this.customer.customerId;
  quoteSummary.extendedMemberProduct = this.extendedMemberProduct;
  quoteSummary.mainMemberBenefits = this.mainMemberBenefits;
  quoteSummary.extendedMemberBenefits = this.extendedMemberBenefits;
  //fixing new product object
  quoteSummary.mainMemberProduct.productType = null;
  quoteSummary.mainMemberProduct.subPlanType = null;
  quoteSummary.mainMemberProduct.planType = null;
  quoteSummary.mainMemberProduct.subGroup = null;

  quoteSummary.extendedMemberProduct.productType = null;
  quoteSummary.extendedMemberProduct.subPlanType = null;
  quoteSummary.extendedMemberProduct.planType = null;
  quoteSummary.extendedMemberProduct.subGroup = null;

  //end fix


	  quoteSummary.b45664925 = this.mainMemberBenefitsAmt;
	  quoteSummary.c4566446 = this.extendedMemberBenefitsAmt;
	  quoteSummary.r45636250 = this.totalPremium;
	  quoteSummary.numOfDependents = this.numOfDependents;
  quoteSummary.subGroupName = this.subgroup;
  quoteSummary.nyaradzoFranchise = "LOCAL";
	  quoteSummary.t45663625 = this.extendedMemberPremium;
	  if(this.customer === null){
		this.openDialog(quoteSummary);
    } else {
	  this.service.save(quoteSummary, '/easipol/local/quote/save').subscribe(
      result => {


			   this.router.navigate(['get_covered/members/' + false + '/' + result.id]);
		  },
		  error => {
			console.log(error);
		  }
	  )
	}

}
openDialog(data) {
	const modalRef = this.modalService.open(PasswordComponent,  { size: 'lg', centered: true });
	modalRef.componentInstance.data = data;
	modalRef.componentInstance.saveUrl = '/easipol/local/quote/save';
	modalRef.componentInstance.isSip = false;
	modalRef.componentInstance.summary.subscribe((result) => {
		if(result !== undefined){


			 this.cdr.markForCheck();
		}
	})
}
onChangePlanTypesNew(memberType: string){
	if(memberType === 'MAIN_MEMBER'){
	  this.planTypesForDependents = this.planTypes.filter(elem => elem.position <= this.planType.position);
	}
  let productType = this.subgroup === 'SIXPACK' ? 'FAMILY' : 'INDIVIDUAL';

  let subgroupId = this.getSubgroupId(this.subgroup);
	if (this.planType !== undefined && this.subPlanType !== undefined) {
    this.isProductSelected = true;
    const premiumCalculator = {currencyCode: this.currencyCode, subGroupId: subgroupId, productType: productType, planType: this.planType.name, mainMemberProduct: this.mainMemberProduct, extendedProduct: this.extendedMemberProduct,
       memberType: memberType, numberOfMembers: this.numOfDependents, markedForZimDependent: false, subPlanType:this.subPlanType, mainMemberBenefits: this.mainMemberBenefits, extendedMemberBenefits: this.extendedMemberBenefits}
    //console.log(premiumCalculator);
    this.service.postAny(premiumCalculator, '/product/sub/premium-calculator').subscribe(
      result => {
         // console.log(result);
          this.mainMemberProduct = result?.mainMemberProduct;

				  this.mainMemberPremium = result?.mainMemberPremium;
          this.extendedMemberProduct = result?.extendedProduct;
          this.extendedMemberPremium = result?.extendedMemberPremium;
          this.totalPremium = result?.totalPremium;
          this.subGroupObj = result?.subGroup;
          this.supplimentaryBenefits = this.subGroupObj?.benefits;
          this.extendedMemberBenefitsAmt = result?.extendedMemberBenefitPremium;
          this.mainMemberBenefitsAmt = result?.mainMemberBenefitPremium;
      this.cdr.markForCheck();
			},
			error => {
				console.log(error);
			},
			() => {

			  this.cdr.markForCheck();
			}
    );

	} else{
    this.isProductSelected = false;
  }
  this.cdr.detectChanges();
  this.cdr.markForCheck();
}
numOfDependentsChange(){
	if(this.numOfDependents === null){
		this.numOfDependents = 0;
	}
	this.dependentsCount = Array(this.numOfDependents).fill(0).map((x,i)=>i + 1);
	this.onChangePlanTypesNew("EXTENDED");
}

getSubgroupId(subgroup: string): number{
 // console.log(subgroup);
  if(subgroup === 'SIXPACK' && this.currencyCode === 'ZiG'){
         return 420;
  } else if(subgroup === 'SIXPACK' && this.currencyCode === 'USD'){
         return 4597;
  }else if(subgroup === 'SCOREPACK' && this.currencyCode === 'USD'){
    return 4594;
  }else if(subgroup === 'SCOREPACK' && this.currencyCode === 'ZiG'){
    return 1825;
  }
}

onChangeMainBenefits(value) {

      this.mainMemberBenefits = [];
      this.mainMemberBenefits.push(value);
      this.onChangePlanTypesNew("MAIN_MEMBER");
      this.cdr.detectChanges();

}
onChangeExtendedBenefits(value){
  this.extendedMemberBenefits = [];
  this.extendedMemberBenefits.push(value);
  this.onChangePlanTypesNew("EXTENDED");
  this.cdr.detectChanges();

}


}
