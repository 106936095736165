import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStorage } from 'src/app/core/auth.storage';

import { routes } from '../../../../consts';
import { User } from '../../../../pages/auth/models';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {
  @Input() user: User;
  @Output() signOut: EventEmitter<void> = new EventEmitter<void>();
  public routes: typeof routes = routes;
  public flatlogicEmail: string = "https://nyaradzo.co.zw";

  constructor(private authStorage: AuthStorage, private router: Router) {
	}
  public signOutEmit(): void {
     this.authStorage.logOut();
		//  window.location.href="https://selfservice.nyaradzo.co.zw";
    this.signOut.emit();
  }
  navigate(link){
    this.router.navigate([link]);
 }
}
