import { Component, Input, OnInit } from '@angular/core';
import { CrudService } from 'src/app/core/crud.service';

@Component({
  selector: 'app-zim-switch-visa-mastercard',
  templateUrl: './zim-switch-visa-mastercard.component.html',
  styleUrls: ['./zim-switch-visa-mastercard.component.scss']
})
export class ZimSwitchVisaMastercardComponent implements OnInit {

  @Input() amount;
  @Input() currency;
  @Input() reference;
  @Input() description;
  loading = false;

  constructor(private service: CrudService) { }

  ngOnInit() {
    // Define your callback functions here
    window['errorCallback'] = (error) => {
      console.log(JSON.stringify(error));
    };

    window['cancelCallback'] = () => {
      console.log('Payment cancelled');
    };

    window['completeCallback'] = (resultIndicator, sessionVersion) => {
      console.log('Payment completed');
      console.log('Result indicator:', resultIndicator);
      console.log('Session version:', sessionVersion);
    };
    this.initCheckout();

  }

  proceedToPayment() {
    window['Checkout'].showPaymentPage();
  }

  initCheckout(): void {

   this.loading = true;
   const order = {amount: this.amount, currency: this.currency, reference: this.reference, description: this.description };
   this.service.postAny(order, '/payments/zim-switch-mastercard/p/checkout').subscribe(
     result => {
     this.loading = false;
     console.log(result);
     window['Checkout'].configure({
      session: {
        id: result.id
      }
    });

     },
     error => {
     this.loading = false;
     console.log(error);
     }
   )
}
}
