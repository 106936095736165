import { SharedModule } from './../shared.module';
import { PasswordComponent } from './password/password.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';


import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxLoadingModule } from 'ngx-loading';
import {  NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChooseSpecificCountryComponent } from './choose-specific-country/choose-specific-country.component';
import {NgxPrintModule} from 'ngx-print';
import { AddMembersComponent } from './add-members/add-members.component';
import { AddMemberDialogComponent } from './add-member-dialog/add-member-dialog.component';
import { LocalQuotesComponent } from './local-quotes/local-quotes.component';
import { GetQuoteComponent } from './get-quote/get-quote.component';
import { DownloadFormDialogComponent } from './download-form-dialog/download-form-dialog.component';
import { ViewPolicyApplicationComponent } from './view-policy-application/view-policy-application.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatStepperModule} from '@angular/material/stepper';
import { ZympayComponent } from './zympay/zympay.component';
import { HeaderAuthComponent } from './header/header.component';
import { ZympayPaymentTypeComponent } from './zympay-payment-type/zympay-payment-type.component';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import {MatRadioModule} from '@angular/material/radio';
import { ZimSwitchVisaMastercardComponent } from './zim-switch-visa-mastercard/zim-switch-visa-mastercard.component';
import { StatusUpdateComponent } from './status-update/status-update.component';

@NgModule({
    imports: [
        CommonModule,
        MatFormFieldModule,
        FormsModule,
        NgxLoadingModule,
        NgbModule,
        NgxPrintModule,
        MatListModule,
        NgxIntlTelInputModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatStepperModule,
        MatInputModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatTableModule,
        MatRadioModule,
        MatButtonModule, MatTooltipModule, MatMenuModule,
        MatIconModule,
        MatSelectCountryModule,
        MatDividerModule,
        MatDialogModule,
        MatSelectModule,
        MatCardModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        SharedModule
    ],
    exports: [ChooseSpecificCountryComponent, AddMembersComponent, LocalQuotesComponent, GetQuoteComponent, ZympayPaymentTypeComponent, ZimSwitchVisaMastercardComponent,
        PasswordComponent, DownloadFormDialogComponent, ViewPolicyApplicationComponent, ZympayComponent, HeaderAuthComponent, StatusUpdateComponent],
    declarations: [ChooseSpecificCountryComponent, AddMembersComponent, AddMemberDialogComponent, ZympayComponent, HeaderAuthComponent, ZimSwitchVisaMastercardComponent,
        LocalQuotesComponent, GetQuoteComponent, PasswordComponent, DownloadFormDialogComponent, ViewPolicyApplicationComponent, ZympayPaymentTypeComponent, ZimSwitchVisaMastercardComponent, StatusUpdateComponent],
    providers: [DatePipe,]
})
export class SharedComponentsModule {}
