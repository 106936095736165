import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-zympay-payment-type',
  templateUrl: './zympay-payment-type.component.html',
  styleUrls: ['./zympay-payment-type.component.scss']
})
export class ZympayPaymentTypeComponent implements OnInit {
  @Input() onceOffLink: string;
  @Input() recurringLink: string;

  constructor(private router: Router) { }

  ngOnInit() {
  }
  navigate(link) {
		this.router.navigate([link]);
	}

}
