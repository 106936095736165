import { Global } from './../global';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtTokenResponse } from './model/token.response';


@Injectable()
export class AuthService {
    private getTokenUrl = this.global.baseUrl + '/v1/api/authentication';
    constructor(private http: HttpClient,  private global: Global) {}
    // Authentication/Authorization
    login(userName: string, password: string): Observable<JwtTokenResponse> {
        const credentials = { userName, password };
        return this.http.post<JwtTokenResponse>(this.getTokenUrl, credentials);
    }
}
