import { AuthStorage } from './../../../core/auth.storage';
import { CrudService } from './../../../core/crud.service';
import { QuoteSummary } from './../../../core/model/quote.summary.model';
import { AddMemberDialogComponent } from './../add-member-dialog/add-member-dialog.component';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, Input, SimpleChanges, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MatStepper } from '@angular/material/stepper';
import SignaturePad from 'signature_pad';
import { BuiltinMethod } from '@angular/compiler';

@Component({
  selector: 'kt-add-members',
  templateUrl: './add-members.component.html',
  styleUrls: ['./add-members.component.scss']
})
export class AddMembersComponent implements OnInit, AfterViewInit {

  @ViewChild('stepper') stepper: MatStepper;
	form: FormGroup;
	mainMemberGroup: FormGroup;
	membersGroup: FormGroup;
	bankingDetailsGroup: FormGroup;
	customer: any;
	country: any;
	@ViewChild('content', {static: false})
	private content: TemplateRef<any>;
	@Input() quoteSummary: QuoteSummary;
	@Input() isSip: boolean;
	policyApplication = null;
	@Input() applicationId: any;
	childArray: any[] = [];
	extendedMemberArray: any[] = [];
	beneficiaryArray: any[] = [];
	banks: any[] = [];
	loading = false;
	hasSpouse = false;
	dependentsCount = 0;
  spouseProduct = null;
  actualPremium = 0.0;
	days: number[] = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]

	//Payments

	payment: any;
	message = '';
	paymentMethodSelected = 'Not Selected';
	paymentMethod: any;
	paymentMobileNumber: number;
	application: any;
	signature: any;
	dateNow = new Date();
	minDate = new Date();
	maxDate = new Date();
  mainMemberIsIdValid = false;
  isSpouseIdValid = false;

  policyResponse: any;
  policyCreated = false;
  payNowLink = '';
  constructor(private fb: FormBuilder, private service: CrudService, private cdr: ChangeDetectorRef, private notification: ToastrService,
	 private modalService: NgbModal, private router: Router, private authStorage: AuthStorage, public datepipe: DatePipe) { }

  ngOnInit() {
	  this.dependentsCount = this.quoteSummary.numOfDependents;
	  this.minDate = moment(this.dateNow).subtract(100, 'year').toDate();
	  this.maxDate = moment(this.dateNow).subtract(18, 'year').toDate();
    // console.log(this.quoteSummary.subGroupName);
    // console.log(this.dependentsCount);

	  this.getBanks();
    this.createForm();

	  if(this.applicationId !== null){

		this.getApplication();
	}

  }


	ngAfterViewInit(): void {

    const controls = this.form['controls'].mainMember['controls'];
   // const spouseControls = this.form['controls'].spouse['controls'];

    if(!this.mainMember.valid){
      Object.keys(controls).forEach(controlName =>
      controls[controlName].markAsTouched());

    }
    if (this.quoteSummary.subGroupName !== 'SCOREPACK') {
      this.dependentsCount = this.dependentsCount - 1;
      this.actualPremium = this.quoteSummary?.r45636250;
      this.haveSpouse(true);
    }

    //   if( this.beneficiaryArray.length === 0){
    //   this.notification.error('Beneficiary Required', 'Please add beneficiary');
    //  }
    //   if(this.signature === undefined){
    //   this.notification.info('Signature Required', 'Please write a signature on the pad below');
    //  }
	}

  calculateActualPremium() {
    if (this.quoteSummary.subGroupName === 'SCOREPACK') {
      let mainMemberProduct = this.quoteSummary?.mainMemberProduct;
      let extendedMemberProduct = this.quoteSummary?.extendedMemberProduct;
      this.quoteSummary.t45663625 = this.extendedMemberArray.length * extendedMemberProduct?.premium;
      this.actualPremium = this.quoteSummary?.b45664925 + mainMemberProduct?.premium; //Main Member total Premium
      this.actualPremium = this.actualPremium + this.quoteSummary.t45663625;// Exended Member Premium
      let exBenPremium = 0.0;
      for (let ben of this.quoteSummary?.mainMemberBenefits) {
        exBenPremium = exBenPremium + (this.extendedMemberArray.length * ben?.premium);
        this.actualPremium = this.actualPremium + (this.extendedMemberArray.length * ben?.premium);
      }

      if (this.hasSpouse) {
        this.actualPremium = this.actualPremium + extendedMemberProduct?.premium + this.quoteSummary?.b45664925;
        exBenPremium = exBenPremium + this.quoteSummary?.b45664925;
      }
      this.quoteSummary.c4566446 = exBenPremium;
    } else {
      this.actualPremium = this.quoteSummary?.r45636250;
    }
    this.cdr.markForCheck();
  }

	convertDate(date): string{

		let latest_date =this.datepipe.transform(date, 'yyyy-MM-dd');
		return latest_date;
     }

	createForm() {
		this.form = this.fb.group({
			id: new FormControl(),
			version: new FormControl(),
			createdBy: new FormControl(),
			reference: new FormControl(),
			dateCreated: new FormControl(new Date()),
			uuid: new FormControl(),
			paymentMethod: new FormControl(),
			bankDetail: this.createBankingDetail(),
			mainMember: this.createMainMember(),
			spouse: this.createMemberSpouse(),
			beneficiaries: this.fb.array([]),
			children: this.fb.array([]),
			signature: new FormControl(),
			extendedMembers: this.fb.array([])
		});
    //this.setIdNumberError('M');
	}
	getApplication(){

		this.service.getAny('/easipol/local/quote/get-application/' + this.applicationId).subscribe(
      result => {
       // console.log(result);

				this.policyApplication = result;
        this.setApplicationValues();

			},
			error => {
				console.log(error);
			},
			() => {
			  this.loading = false;
			  this.cdr.markForCheck();
			}
		)
	}
  validateIdNumber(idNumber: string, memberType: string){

          if(idNumber != undefined && idNumber !== null){
            this.setIdNumberError(memberType);
            if(idNumber.length >= 10){
              if(memberType === 'M'){
                this.mainMemberIsIdValid = true;
                this.form.get('mainMember.idNumber').setErrors(null);
              }
              // this.isSpouseIdValid = true;
              if(this.hasSpouse && memberType === 'S'){
                this.isSpouseIdValid = true;
                this.form.get('spouse.idNumber').setErrors(null);
              }
              // this.service.getAny('/customer/id-validation/validate/person?idNumber=' + idNumber).subscribe(
              //   result => {
              //    // console.log(result);
              //     if(result !== null){
              //         if(result.status === 'A'){
              //           if(memberType === 'M'){
              //             this.mainMemberIsIdValid = true;
              //             this.form.get('mainMember.idNumber').setErrors(null);
              //           }
              //           if(this.hasSpouse && memberType === 'S'){
              //             this.isSpouseIdValid = true;
              //             this.form.get('spouse.idNumber').setErrors(null);
              //           }

              //         } else if(result.status === 'E'){
              //           this.setIdNumberError(memberType);
              //         } else{
              //           this.setIdNumberError(memberType);
              //         }
              //     }
              //   },
              //   error => {
              //     console.log(error);
              //   },
              //   () => {
              //     this.loading = false;
              //     this.cdr.markForCheck();
              //   }
              // )
            } else{
              this.setIdNumberError(memberType);
            }
          } else{
            this.setIdNumberError(memberType);
          }
          this.cdr.markForCheck();
	}

  setIdNumberError(memberType: string){
    if(memberType === 'M'){
      this.mainMemberIsIdValid = false;
      this.form.get('mainMember.idNumber').setErrors({'incorrect': true});
    }
    if(memberType === 'S'){
      this.isSpouseIdValid = false;
      this.form.get('spouse.idNumber').setErrors({'incorrect': true});
    }
    this.cdr.markForCheck();
  }
  setApplicationValues() {
   // console.log(this.policyApplication);

		if(this.policyApplication != null){
			this.form.get('id').setValue(this.policyApplication!.id);
			this.form.get('version').setValue(this.policyApplication!.version);
			this.form.get('createdBy').setValue(this.policyApplication!.createdBy);
			this.form.get('reference').setValue(this.policyApplication!.reference);
			this.form.get('dateCreated').setValue(this.policyApplication!.dateCreated);
	    this.mainMember.get('dateOfBirth').setValue(this.convertDate(this.policyApplication!.mainMember!.dateOfBirth));
			this.form.get('mainMember.maritalStatus').setValue(this.policyApplication!.mainMember!.maritalStatus);
			this.form.get('mainMember.mobileNumber').setValue(this.policyApplication!.mainMember!.mobileNumber);
			this.form.get('mainMember.idNumber').setValue(this.policyApplication!.mainMember!.idNumber);
      this.validateIdNumber(this.policyApplication!.mainMember!.idNumber, 'M');

			this.form.get('mainMember.nationality').setValue(this.policyApplication!.mainMember!.nationality);
			this.form.get('mainMember.passportNumber').setValue(this.policyApplication!.mainMember!.passportNumber);
			this.form.get('mainMember.email').setValue(this.policyApplication!.mainMember!.email);
			this.form.get('mainMember.street').setValue(this.policyApplication!.mainMember!.street);

			this.form.get('mainMember.title').setValue(this.policyApplication!.mainMember!.title);
			this.form.get('mainMember.lastName').setValue(this.policyApplication!.mainMember!.lastName);
			this.form.get('mainMember.firstName').setValue(this.policyApplication!.mainMember!.firstName);
			this.form.get('mainMember.province').setValue(this.policyApplication!.mainMember!.province);

			this.form.get('mainMember.country').setValue(this.policyApplication!.mainMember!.country);
			this.form.get('mainMember.gender').setValue(this.policyApplication!.mainMember!.gender);
			this.form.get('mainMember.customerId').setValue(this.policyApplication!.mainMember!.customerId);
			this.form.get('mainMember.city').setValue(this.policyApplication!.mainMember!.city);
			this.paymentMethod = this.policyApplication!.paymentMethod;
			this.form.get('paymentMethod').setValue(this.paymentMethod);

			//this.form.get('signature').setValue(this.policyApplication!.signature);
			if(this.policyApplication!.spouse!.firstName !== null){
				this.hasSpouse = true;
			this.form.get('spouse.idNumber').setValue(this.policyApplication!.spouse!.idNumber);
      this.validateIdNumber(this.policyApplication!.spouse!.idNumber, 'S');
			this.form.get('spouse.lastName').setValue(this.policyApplication!.spouse!.lastName);
			this.form.get('spouse.firstName').setValue(this.policyApplication!.spouse!.firstName);
			this.form.get('spouse.gender').setValue(this.policyApplication!.spouse!.gender);


			this.form.get('spouse.passportNumber').setValue(this.policyApplication!.spouse!.passportNumber);
			this.form.get('spouse.mobileNumber').setValue(this.policyApplication!.spouse!.mobileNumber);
			this.form.get('spouse.dateOfBirth').setValue(this.convertDate(this.policyApplication!.spouse!.dateOfBirth));
			this.form.get('spouse.email').setValue(this.policyApplication!.spouse!.email);
			}
			if(this.policyApplication.children.length > 0){

				for (let index = 0; index < this.policyApplication.children.length; index++) {
					const member = this.policyApplication.children[index];
					member.position = index;
					member.dateOfBirth = this.convertDate(member!.dateOfBirth)
					this.addChild(member);
					this.childArray.push(member);


				}

			}

			if(this.policyApplication.extendedMembers.length > 0){

				for (let index = 0; index < this.policyApplication.extendedMembers.length; index++) {
					const member = this.policyApplication.extendedMembers[index];
					member.position = index;
					member.dateOfBirth = this.convertDate(member!.dateOfBirth)
					this.addExtendedMember(member);
					this.extendedMemberArray.push(member);


				}

			}
			if(this.policyApplication.beneficiaries.length > 0){

				for (let index = 0; index < this.policyApplication.beneficiaries.length; index++) {
					const member = this.policyApplication.beneficiaries[index];
					member.position = index;
					member.dateOfBirth = this.convertDate(member!.dateOfBirth)
					this.addBeneficiary(member);
					this.beneficiaryArray.push(member);

				}

			}


    }


    this.calculateActualPremium();
	}
	onSubmit() {


			let postData = this.form.value;
			postData.paymentMethod = this.paymentMethodSelected;
			postData.paymentMobileNumber = this.paymentMobileNumber;
			postData.localQuoteSummary = this.quoteSummary;
			postData.isSpouseAvailable = this.hasSpouse;
      postData.spouseProduct = this.spouseProduct;
      postData.actualPremium = this.actualPremium;
		//	this.open(this.content);
			this.loading = true;
			this.cdr.markForCheck();

			this.service.postAny(postData, '/easipol/local/quote/create-policy').subscribe(
				result => {
          this.loading = false;
          let res = result;
          if (res != null) {

            if (res.initSuccess) {
              // console.log("Init Success..");
              // console.log(res.redirectUrl);
              this.policyCreated = true;
              this.payNowLink = res.redirectUrl;
              this.openWindow(res.redirectUrl);
              this.cdr.markForCheck();
						}
					}

						// this.modalService.dismissAll(ModalDismissReasons.BACKDROP_CLICK);
						// if(result.policyApp !== null){
						// 	this.applicationId = result.policyApp;
						// 	this.setApplicationValues();
						// 	// if(result.policyApp.paymentStatus === 'PAID'){
            //                     this.router.navigate(['/get_covered/application']);
						// //	}
						// }

					this.cdr.markForCheck();
				},
				error => {
					// this.router.navigate(['/get_covered/application']);
					// this.notification.error('Notification', 'Error Encountered.');
					this.loading = false;
					this.cdr.markForCheck();
				}
			);


  }

  openWindow(link) {
    window.open(link,'popup','width=900,height=900,scrollbars=yes,resizable=yes');
  //  window.open(link, '_blank');
  }
  onNavigate(){
    window.open(this.payNowLink,'popup','width=900,height=900,scrollbars=yes,resizable=yes');
}
	haveSpouse(hevSpouse: boolean){
    this.hasSpouse = hevSpouse;
    const spouseControls = this.form['controls'].spouse['controls'];
		if(hevSpouse){
      const idNum = this.form.get('spouse.idNumber').value;
      this.updateSpouseValidation(spouseControls);

			this.getSpouseProduct();
			this.dependentsCount = this.quoteSummary.numOfDependents - 1;
      this.validateIdNumber(idNum, 'S');
      this.calculateActualPremium();

		} else {
      Object.keys(spouseControls).forEach(controlName => {
        spouseControls[controlName].clearValidators();
        spouseControls[controlName].updateValueAndValidity();
      });
			this.dependentsCount = this.quoteSummary.numOfDependents;
      this.stepper.next();
		}
		this.cdr.markForCheck();

  }
  updateSpouseValidation(spouseControls){

    Object.keys(spouseControls).forEach(controlName =>{

      if(controlName !== 'id' && controlName !== 'version' && controlName !== 'createdBy' && controlName !== 'dateCreated' &&  controlName !== 'mobileNumber' &&
        controlName !== 'title' && controlName !== 'passportNumber' &&  controlName !== 'relationship' && controlName !== 'email'){
          // console.log(controlName);
      spouseControls[controlName].setValidators([Validators.required]);
      spouseControls[controlName].updateValueAndValidity();
      spouseControls[controlName].markAsTouched();
    }
    });


     // this.cdr.markForCheck();

  }
  getSpouseProduct() {
    console.log('getting spouse product..');
    console.log(this.quoteSummary.subGroupName + 'P');

    if (this.quoteSummary.subGroupName === 'SCOREPACK') {
      console.log("...if");

      const link = '/product/sub/get-by-subgroup-and-types?subgroupid=' + this.quoteSummary?.mainMemberProduct?.subGroupId + '&productType=' + this.quoteSummary?.mainMemberProduct?.productTypeName + '&planType=' + this.quoteSummary?.mainMemberProduct?.planTypeName + '&memberType=SPOUSE&markedForZimDependent=false&subPlanType=' + this.quoteSummary?.mainMemberProduct?.subPlanTypeName;
      console.log(link);

			this.service.getAny(link).subscribe(
        result => {
          //console.log(result);

          this.spouseProduct = result;
          this.spouseProduct!.productType = null;
          this.spouseProduct!.subPlanType = null;
          this.spouseProduct!.planType = null;
          this.spouseProduct!.subGroup = null;

          this.calculateActualPremium();
          this.cdr.markForCheck();

				}
			);
    } else {
      this.spouseProduct.premium = 0.0;
      this.calculateActualPremium();
    }
	}
	paymentMethodChange(method) {
		this.paymentMethodSelected = method;
	//	this.form.get('mobileMoneyMethod').setValue(method);
		this.cdr.markForCheck();

	}
	validateMobileNumber(phoneNumber: number): boolean{
             if(phoneNumber !== undefined){
                 if(phoneNumber.toString().length === 9){
					 return true;
				 } else{
					 return false;
				 }
			 } else{
				 return false;
			 }
	}
	drawComplete(data) {
    // will be notified of szimek/signature_pad's onEnd event
    console.log(data);

	//	this.signature = this.signaturePad.toDataURL('image/png', 0.5);
		this.form.get('signature').setValue(data);
		//console.log(this.signaturePad.toDataURL());
	  }

	  //  clearSignature() {
		// this.signaturePad.clear();
		// this.signature = undefined;
	  // }
	get mainMember() { return this.form.get('mainMember'); }
	createMainMember() {
		this.mainMemberGroup = new FormGroup({
			id: new FormControl(this.quoteSummary.customer.id),
			version: new FormControl(this.quoteSummary.customer!.version),
			createdBy: new FormControl(this.quoteSummary.customer!.createdBy),
			dateCreated: new FormControl(this.quoteSummary.customer!.dateCreated),
			title: new FormControl(this.quoteSummary.customer!.title, Validators.compose([Validators.required])),
			firstName: new FormControl(
				this.quoteSummary.customer!.firstName,
				Validators.compose([
					Validators.maxLength(25),
					Validators.minLength(2),
					Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
					Validators.required
				])
			),
			lastName: new FormControl(
				this.quoteSummary.customer!.lastName,
				Validators.compose([
					Validators.maxLength(25),
					Validators.minLength(2),
					Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
					Validators.required
				])
			),
			customerId: new FormControl(this.quoteSummary.customer!.customerId),
			nationality: new FormControl('Zimbabwean', Validators.compose([Validators.required])),
			idNumber: new FormControl(this.quoteSummary.customer!.idNumber, Validators.compose([Validators.required])),
			passportNumber: new FormControl(this.quoteSummary.customer!.passportNumber, this.isSip ? Validators.compose([Validators.required]): Validators.compose([])),
			dateOfBirth: new FormControl(this.quoteSummary.customer!.dateOfBirth, Validators.compose([Validators.required])),
			maritalStatus: new FormControl(this.quoteSummary.customer!.maritalStatus, Validators.compose([Validators.required])),
			email: new FormControl(this.quoteSummary.customer!.email, Validators.compose([Validators.required])),
		    province: new FormControl(this.quoteSummary.customer!.province, Validators.compose([Validators.required])),
			street: new FormControl(this.quoteSummary.customer!.street, Validators.compose([Validators.required])),
			gender: new FormControl(this.quoteSummary.customer!.gender, Validators.compose([Validators.required])),
			mobileNumber: new FormControl(this.quoteSummary.customer!.mobileNumber, Validators.compose([Validators.required])),
			postalAddress: new FormControl(this.quoteSummary.customer!.postalAddress),
			physicalAddress: new FormControl(this.quoteSummary.customer!.physicalAddress),
			city: new FormControl(this.quoteSummary.customer!.city, Validators.compose([Validators.required])),
			country: new FormControl(this.isSip? this.quoteSummary.country.countryName : this.quoteSummary.customer!.country),
			personalityType: new FormControl(this.quoteSummary.customer!.personalityType),
			memberType: new FormControl('M'),

		});
		return this.mainMemberGroup;
	}
	get spouse() { return this.form.get('spouse'); }
	createMemberSpouse() {
		this.membersGroup = new FormGroup({
			id: new FormControl(null),
			version: new FormControl(null),
			createdBy: new FormControl(null),
			dateCreated: new FormControl(null),
			title: new FormControl(null),
			firstName: new FormControl(
				null,
				Validators.compose([
					Validators.maxLength(25),
					Validators.minLength(2),
					// Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
					Validators.required
				])
			),
			lastName: new FormControl(
				null,
				Validators.compose([
					Validators.maxLength(25),
					Validators.minLength(2),// 			this.service.getAny('/easipol/local/quote/confirm-payment?id=' + this.application.id).subscribe(
						// 				result =>{
					Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
					Validators.required
				])
			),
			idNumber: new FormControl(null, Validators.required),
			memberType: new FormControl('S'),
			dateOfBirth: new FormControl(null, Validators.required),
			gender: new FormControl(null, Validators.required),
			mobileNumber: new FormControl(null),
			email: new FormControl(null),
			relationship: new FormControl(null),
			passportNumber: new FormControl(null),

		});
		return this.membersGroup;
	}
	createBankingDetail() {
		this.bankingDetailsGroup = new FormGroup({

			bank: new FormControl(null),
			accountName: new FormControl(null),
			accountNumber: new FormControl(null),
			typeOfAccount: new FormControl(null),
			branchCode: new FormControl(null),
			debitDay: new FormControl(null),
			debitNote: new FormControl(null),

		});
		return this.bankingDetailsGroup;
	}
	createMember(membertype: string, data: any) {
		this.membersGroup = new FormGroup({
			id: new FormControl(null),
			version: new FormControl(null),
			createdBy: new FormControl(null),
			dateCreated: new FormControl(null),
			position: new FormControl(data.position),
			title: new FormControl(data.title),
			firstName: new FormControl(
				data.firstName,
				Validators.compose([
					Validators.maxLength(25),
					Validators.minLength(2),
					// Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
					Validators.required
				])
			),
			lastName: new FormControl(
				data.lastName,
				Validators.compose([
					Validators.maxLength(25),
					Validators.minLength(2),
					Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
					Validators.required
				])
			),
			idNumber: new FormControl(data.idNumber, Validators.required),
			memberType: new FormControl(membertype),
			dateOfBirth: new FormControl(data.dateOfBirth, Validators.required),
			gender: new FormControl(data.gender),
			mobileNumber: new FormControl(data.mobileNumber),
			email: new FormControl(data.email),
			relationship: new FormControl(data.relationship),
			passportNumber: new FormControl(data.passportNumber),

		});
		return this.membersGroup;
	}
	openDialog(edit: boolean, memberType, data) {
		const modalRef = this.modalService.open(AddMemberDialogComponent,  { size: 'lg', centered: true });
		modalRef.componentInstance.memberType = memberType;
		if (edit) {
			modalRef.componentInstance.data = data;
		} else{
			modalRef.componentInstance.data = uuidv4();
		}

		modalRef.componentInstance.memberData.subscribe((memberData) => {
            if(memberData !== undefined){
				switch(memberType) {
					case 'C': {
						if(edit){
						  this.deleteChild(memberData.position);
						}
					    this.childArray.push(memberData);
						this.childArray = [...this.childArray];
						this.addChild(memberData);
					   break;
					}
					case 'E': {
						   if(edit){
							this.deleteExtendedMember(memberData.position);
						  }
						  this.extendedMemberArray.push(memberData);
						  this.extendedMemberArray = [...this.extendedMemberArray];
						  this.addExtendedMember(memberData);
					   break;
					}
					default: {
						if(edit){
							this.deleteBeneficiary(memberData.position);
						  }
						  this.beneficiaryArray.push(memberData);
						  this.beneficiaryArray = [...this.beneficiaryArray];
						  this.addBeneficiary(memberData);
					   break;
					}
				 }
				 this.cdr.markForCheck();
			}
		})
	}
	get memberChildren() {
		return this.form.get('children') as FormArray;
	}
	addChild(data: any) {
		this.memberChildren.push(this.createMember('C', data));
	}
	deleteChild(position: number) {
		this.childArray = this.childArray.filter(obj => obj.position !== position);
		const i = this.memberChildren.controls.findIndex(obj => obj.value.position === position);
		this.memberChildren.removeAt(i);
	}

	get extendedMember() {
		return this.form.get('extendedMembers') as FormArray;
	}
	addExtendedMember(data: any) {
    this.extendedMember.push(this.createMember('E', data));
    this.calculateActualPremium();
	}
	deleteExtendedMember(position: number) {
		this.extendedMemberArray = this.extendedMemberArray.filter(obj => obj.position !== position);
		const i = this.extendedMember.controls.findIndex(obj => obj.value.position === position);
    this.extendedMember.removeAt(i);
    this.calculateActualPremium();
	}
	get beneficiaries() {
		return this.form.get('beneficiaries') as FormArray;
	}
	addBeneficiary(data: any) {
		this.beneficiaries.push(this.createMember('B', data));
	}
	deleteBeneficiary(position: number) {
		this.beneficiaryArray = this.beneficiaryArray.filter(obj => obj.position !== position);
		const i = this.beneficiaries.controls.findIndex(obj => obj.value.position === position);
		this.beneficiaries.removeAt(i);
	}

	open(content) {
		this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true}).result.then((result) => {
		//   this.closeResult = `Closed with: ${result}`;
		//   console.log(this.closeResult);

		}, (reason) => {
		//   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		//   console.log(this.closeResult);


		});
	  }
	  getBanks(){
		this.service.getList('/easipol/policy/banks').subscribe(
							result =>{
                               this.banks = result;
							},
							error =>{}
		);
	  }


	//   initiatePaymentConfirmation(payment){

	// 			this.loading = true;
	// 			this.cdr.markForCheck();
	// 			console.log('Creating Policy...');
	// 			this.service.getAny('/easipol/local/quote/confirm-payment?id=' + this.application.id).subscribe(
	// 				result =>{
	// 					this.loading = false;
	// 					this.policyResponse = result;
	// 					this.notification.success('Notification', 'Policy Created Successfully');
	// 					if(result.customer !== null){
	// 					this.authStorage.saveUser(JSON.stringify(result.customer));
	// 					}
	// 					this.router.navigate(['/policy/other']);
	// 				/// this.router.navigate(['/get_covered/members/' + false + '/' + this.quoteSummary.id]);
	// 				},
	// 				() =>{
	// 					this.loading = false;
	// 					this.stop();
	// 					this.cdr.markForCheck();
	// 				}
	// 			);


	// }
}
