
 <h4 class="group-title">Welcome!</h4>
 <p class="group-sub-title">Create your account</p>
 <ngb-alert *ngIf="alert" [type]="alert.type">{{ alert.message }}</ngb-alert>
<form class="form" [formGroup]="registerForm" autocomplete="off">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Firstname</mat-label>
          <input matInput type="text" placeholder="Firstname" formControlName="firstName" />
          <mat-error *ngIf="isControlHasError('firstName','required')">
            <strong>Firstname required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Lastname</mat-label>
          <input matInput type="text" placeholder="Lastname" formControlName="lastName"/>
          <mat-error *ngIf="isControlHasError('firstName','required')">
            <strong>Lastname required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Email</mat-label>
          <input matInput type="email" placeholder="Email" formControlName="email" />
          <mat-error *ngIf="isControlHasError('email','required')">
            <strong>Email is required</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email','email')">
            <strong>Email is invalid</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email','minlength')">
            <strong>Email is short</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email','maxlength')">
            <strong>Email is too long</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Mobile Number</mat-label>
          <input matInput type="number" placeholder="Mobile Number" formControlName="mobileNumber"/>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Password</mat-label>
          <input matInput type="password" placeholder="Enter password" formControlName="password" autocomplete="off"/>
          <mat-error *ngIf="isControlHasError('password','required')">
            <strong>Password is required.</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password','minlength')">
            <strong>Password is short</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password','maxlength')">
            <strong>Maximum length is 100</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Confirm password</mat-label>
          <input matInput type="password" placeholder="Re-Enter Password" formControlName="confirmPassword" autocomplete="off"/>
          <mat-error *ngIf="isControlHasError('confirmPassword','required')">
            <strong>Confirm password required</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
            <strong>Password is short.</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
            <strong>Maximum length is 100</strong>
          </mat-error>
          <mat-error *ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
            <strong>Passsword and Confirm Password didn't match.</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div class="form-actions">
    <button class="form-actions__create" mat-raised-button color="primary" type="button" (click)="submitForm()">Create your account <i
      *ngIf="loading" class="fa fa-spinner fa-spin"></i></button>
  </div>
</form>
<div class="bottom-btn">
  <div class="bottom-btn__border-wrapper">
    <div class="bottom-btn__border-line"></div>
    <p class="bottom-btn__border-title">OR</p>
    <div class="bottom-btn__border-line"></div>
  </div>
  <div class="register-button-wrapper">
    <button (click)="navigate('/auth/login')"  mat-raised-button class="register-button">
      Login
    </button>
  </div>
  </div>
