import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, finalize, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth.service';
import { AuthStorage } from 'src/app/core/auth.storage';
import { ConfirmPasswordValidator } from 'src/app/core/confirm-password.validator';
import { CrudService } from 'src/app/core/crud.service';
import { JwtTokenResponse } from 'src/app/core/model/token.response';
import { MessageAlert } from 'src/app/core/model/message.alert';

@Component({
  selector: 'kt-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetToken = '';
  form: FormGroup;
  loading = false;
  tokenExists = false;
  ngxLoading = false;
  messageAlert: MessageAlert = {viewMsg: false, msg: '', type: 'info'};
  private unsubscribe: Subject<any>;
  constructor(private route: ActivatedRoute,
				          private fb: FormBuilder,
				          private authStorage: AuthStorage,
						      private auth: AuthService,
				          private cdr: ChangeDetectorRef,
				          private router: Router,
	                private service: CrudService) {
	this.resetToken = this.route.snapshot.params.resetToken;
	this.unsubscribe = new Subject();

  }

  ngOnInit() {
	  this.initRegisterForm();
	  this.findByResetToken();
  }

  findByResetToken(){
    this.ngxLoading = true;
    this.messageAlert = {viewMsg: false, msg: '', type: 'info'};
	  this.service.getAny('/customer/reset-password/' + this.resetToken).subscribe(
		  result => {
			this.ngxLoading = false;
   if (result !== null) {
        this.messageAlert = {viewMsg: false, msg: 'Enter New Password', type: 'success'};
				this.tokenExists = true;
			 } else {
        this.messageAlert = {viewMsg: false, msg: 'Reset Token not found.', type: 'danger'};
				this.tokenExists = false;
			 }
		  },
		  error => {
			this.ngxLoading = false;
			console.log(error);
		  },
		  () => {
			this.ngxLoading = false;
			this.cdr.markForCheck();
		  }
	  )
  }

  initRegisterForm() {
	this.form = this.fb.group({
		password: ['', Validators.compose([
			Validators.required,
			Validators.minLength(3),
			Validators.maxLength(100)
		])
		],
		confirmPassword: ['', Validators.compose([
			Validators.required,
			Validators.minLength(3),
			Validators.maxLength(100)
		])
		]

	}, {
		validator: ConfirmPasswordValidator.MatchPassword
	});
}
  submit() {
	this.ngxLoading = true;
	const pwd = this.form.get('password').value;
	const resetDetails = {password: pwd, resetToken: this.resetToken};
	this.service.postAny(resetDetails, '/customer/reset-details').subscribe(
		result => {
			this.ngxLoading = false;
   if (result != null) {
        this.messageAlert = {viewMsg: true, msg: 'Logging you in...', type: 'info'};
			 this.authClient(result.email, pwd);
			} else {
        this.messageAlert = {viewMsg: true, msg: 'Failed to reset password', type: 'danger'};
			}
		},
		error => {
      this.messageAlert = {viewMsg: true, msg: 'Error Encounted, Try again', type: 'danger'};
			this.ngxLoading = false;
		},
		() => {
			this.ngxLoading = false;
			this.cdr.markForCheck();
		}
	);

  }
  authClient(email: string, pwd: string) {
	this.ngxLoading = true;
	const authData = {userName: email, password: pwd};
	this.auth
	.login(authData.userName, authData.password)
	.pipe(
		tap((jwtTokenResponse: JwtTokenResponse) => {
			this.ngxLoading = false;
			if (jwtTokenResponse) {
				this.authStorage.saveToken( jwtTokenResponse.token);
				this.authStorage.saveUser(JSON.stringify(jwtTokenResponse.customer));
				this.router.navigate(['/dashboard']);
			} else {
        this.messageAlert = {viewMsg: true, msg: 'Error Encountered !', type: 'danger'};
				this.router.navigateByUrl('/auth/login');
			}
		}),
		takeUntil(this.unsubscribe),
		finalize(() => {
			this.ngxLoading = false;
			// this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
			this.cdr.markForCheck();
		})
	)
	.subscribe();
}

  /**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.form.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
/*
    * On destroy
    */
   ngOnDestroy(): void {
	this.unsubscribe.next();
	this.unsubscribe.complete();
	this.loading = false;
	this.ngxLoading = false;
}
}
