
  <h4 class="group-title">Hello, User</h4>
  <form class="form" [formGroup]="loginForm" autocomplete="off">
    <mat-form-field class="full-width" appearance="fill">
      <input matInput placeholder="Email Address" type="email" formControlName="userName">
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <input matInput placeholder="Password" type="password" formControlName="password">
    </mat-form-field>

    <div class="form-actions confirm-btns">
      <!-- <button class="form-actions__login" mat-raised-button color="primary" (click)="submit()">Login</button> -->
      <button (click)="submit()" [disabled]="loading" class="btn btn-primary btn-elevate  confirm-btn form-actions__login"> Login <i *ngIf="loading" class="fa fa-spinner fa-spin"></i></button>
      <button class="form-actions__forget" (click)="navigate('/auth/forgot-password')" mat-button>Forgot password</button>
    </div>
  </form>
  <div class="bottom-btn">
    <div class="bottom-btn__border-wrapper">
      <div class="bottom-btn__border-line"></div>
      <p class="bottom-btn__border-title">OR</p>
      <div class="bottom-btn__border-line"></div>
    </div>
    <div class="register-button-wrapper">
      <button (click)="navigate('/auth/confirm_client')" mat-raised-button class="register-button">
        Register
      </button>
    </div>
  </div>
