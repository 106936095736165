import { AuthStorage } from './../../../core/auth.storage';
import { CrudService } from './../../../core/crud.service';
import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'kt-choose-specific-country',
  templateUrl: './choose-specific-country.component.html',
  styleUrls: ['./choose-specific-country.component.scss']
})
export class ChooseSpecificCountryComponent implements OnInit {
	@Input() baseUrl;
	countries: any[] = [];
	myControl = new FormControl();
  filteredOptions: Observable<any[]>;
  constructor(private router: Router, private service: CrudService,  private authStorage: AuthStorage, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
	this.filteredOptions = this.myControl.valueChanges
	.pipe(
	  startWith(''),
	  map(value => typeof value === 'string' ? value : value.name),
	  map(name => name ? this._filter(name) : this.countries.slice())
	);
	  this.getCountries();
  }
  selectedCountry(link, id) {
      this.router.navigate([link, id]);
  }
  onChangeCountry(link) {
	this.router.navigate([link]);
  }
  getCountries() {
	this.service.getList('/easipol/country/get-all').subscribe(
		result => {
			this.countries = result;
			console.log(this.countries);
		},
		error => {
			console.log(error);
		},
		() => {
			this.cdr.markForCheck();
		}
		);
}
displayFn(country: any): string {
    return country && country.countryName ? country.countryName : '';
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.countries.filter(option => option.countryName.toLowerCase().indexOf(filterValue) === 0);
  }

}
