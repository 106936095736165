<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content class="mat-typography">

<h4 style="color: red;">Please note that the form is an editable pdf, so just fill in the required details and send the completed form to NLACO@nyaradzo.co.zw.</h4>
</mat-dialog-content>
<mat-dialog-actions align="center">
	<button mat-stroked-button [mat-dialog-close]="true">CANCEL</button>
	<a href="javascript:void(0)" style="cursor:pointer" (click)="downloadPdf(data.link, data.title)" mat-stroked-button cdkFocusInitial>DOWNLOAD</a>
</mat-dialog-actions>


