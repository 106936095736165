<!-- <app-layout> -->
<h5 class="text-center">MAIN MEMBER DETAILS</h5>
<h6 class="text-center">Total Premium - ZWL {{pa?.localQuoteSummary?.totalPremium}}</h6>
<div class="table-responsive">
	<table style="width: 100%;" *ngIf="pa !== null" class="table table-hover  table-striped">
		<tbody>
			<tr>
				<th>First name</th>
				<td>{{pa?.mainMember?.firstName}}</td>
				<th>Last name</th>
				<td> {{pa?.mainMember?.lastName}}</td>
			</tr>
			<tr>
				<th>ID Number</th>
				<td>{{pa?.mainMember?.idNumber}} </td>
				<th>DOB</th>
				<td>{{pa?.mainMember?.dateOfBirth| date: 'dd/MM/yyyy'}} </td>
			</tr>


			<tr>
				<th>Residential Address &nbsp;
				</th>
				<td>{{pa?.mainMember?.address}} </td>
				<th>Postal Address</th>
				<td>{{pa?.mainMember?.address}}</td>
			</tr>
			<tr>
				<th>Contact Details &nbsp;
				</th>
				<td>{{pa?.mainMember?.mobileNumber}} </td>
				<th>Email Address</th>
				<td>{{pa?.mainMember?.email}}</td>
			</tr>
			<tr>
				<th>Product</th>
				<td>{{pa?.localQuoteSummary?.mainMemberProduct?.name}}</td>
				<th>Main Member Premium</th>
				<td>ZWL {{pa?.localQuoteSummary?.mainMemberProduct?.premium}} </td>
			</tr>
			<tr>
				<th>Supplimentary Benefits</th>
				<td>
					<ul>
						<li *ngFor="let item of pa?.localQuoteSummary?.mainMemberBenefits" >{{item.name}} = ZWL {{item.premium}}</li>
					</ul>
				</td>
				<th></th>
				<th></th>
			</tr>


		</tbody>

	</table>
</div>
<div *ngIf="pa?.spouse?.firstName !== null">
	<h5 class="text-center">SPOUSE DETAILS</h5>
	<div class="table-responsive">
		<table style="width: 100%;"  class="table table-hover  table-striped">
			<tbody>
				<tr>
					<th>First name</th>
					<td> {{pa?.spouse?.firstName}}</td>
					<th>Last name</th>
					<td> {{pa?.spouse?.lastName}}</td>
				</tr>
				<tr>
					<th>ID Number</th>
					<td>{{pa?.spouse?.idNumber}}</td>
					<th>DOB</th>
					<td> {{pa?.spouse?.dateOfBirth}} </td>
				</tr>

				<tr>
					<th>Product</th>
					<td>{{pa?.spouseProduct?.name}} </td>
					<th>Premium</th>
					<td>{{pa?.spouseProduct?.premium}}</td>
				</tr>



			</tbody>

		</table>
	</div>
</div>
<div *ngIf="pa.children.length > 0">
	<h5 class="text-center">CHILDREN DETAILS</h5>
	<div class="table-responsive">
		<table class="table">
			<thead class="thead-light">
				<tr>
					<th scope="col">#</th>
					<th scope="col">Fullname</th>
					<th scope="col">ID</th>
					<th scope="col">Date Of Birth</th>
					<th scope="col">Gender</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let member of pa.children; index as i">
					<th scope="row">{{i + 1}}</th>
					<td>{{member?.firstName}} {{member?.lastName}}</td>
					<td>{{member?.idNumber}}</td>
					<td>{{member?.dateOfBirth | date: 'dd/MM/yyyy'}}</td>
					<td>{{member?.gender}}</td>
					<td></td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<div *ngIf="pa.extendedMembers.length > 0">
	<h5 class="text-center">EXTENDED DETAILS</h5>
	<div class="table-responsive">
		<table class="table">
			<thead class="thead-light">
				<tr>
					<th scope="col">#</th>
					<th scope="col">Fullname</th>
					<th scope="col">ID</th>
					<th scope="col">Date Of Birth</th>
					<th scope="col">Gender</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let member of pa.extendedMembers; index as i">
					<th scope="row">{{i + 1}}</th>
					<td>{{member?.firstName}} {{member?.lastName}}</td>
					<td>{{member?.idNumber}}</td>
					<td>{{member?.dateOfBirth | date: 'dd/MM/yyyy'}}</td>
					<td>{{member?.gender}}</td>
				</tr>
				<tr>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<th>Total Extended Premium</th>
					<td>ZWL {{pa.localQuoteSummary?.extendedMemberPremium}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<div *ngIf="pa.beneficiaries.length > 0">
	<h5 class="text-center">BENEFICIARIES DETAILS</h5>
	<div class="table-responsive">
		<table class="table">
			<thead class="thead-light">
				<tr>
					<th scope="col">#</th>
					<th scope="col">Fullname</th>
					<th scope="col">ID</th>
					<th scope="col">Date Of Birth</th>
					<th scope="col">Gender</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let member of pa.beneficiaries; index as i">
					<th scope="row">{{i + 1}}</th>
					<td>{{member?.firstName}} {{member?.lastName}}</td>
					<td>{{member?.idNumber}}</td>
					<td>{{member?.dateOfBirth | date: 'dd/MM/yyyy'}}</td>
					<td>{{member?.gender}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<!-- </app-layout> -->
