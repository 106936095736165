import { Interceptor } from './core/inteceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthModule } from './pages/auth/auth.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Global } from './global';
import { AuthStorage } from './core/auth.storage';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { AuthService } from './core/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AuthModule,
    HttpClientModule,
    DashboardModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    MatNativeDateModule,
    MatCardModule,
    MatButtonModule,
    NgbModule,
    MatSelectCountryModule.forRoot('de'),
    NgxLoadingModule.forRoot({ animationType: ngxLoadingAnimationTypes.pulse,
			backdropBackgroundColour: 'rgba(0,0,0,0.1)',
			backdropBorderRadius: '4px',
			primaryColour: '#5d78ff',
			secondaryColour: '#fd397a',
			tertiaryColour: '#ffffff'}),
  ],
  providers: [
     AuthService,
     AuthStorage,
     Global,
    {
			provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
			multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
