import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-view-policy-application',
  templateUrl: './view-policy-application.component.html',
  styleUrls: ['./view-policy-application.component.scss']
})
export class ViewPolicyApplicationComponent implements OnInit {

  @Input() pa: any;
  @Input() isModal: boolean;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {

  }

}
