export class Global {




  // *** local ***//
  //  baseUrlPG = 'http://localhost:8270';
  // baseUrl = 'http://localhost:8082';
  // devUrl = 'http://localhost:4200';
  // activeUrl = 'http://localhost:4200';
  // posSuccessCode = '02';

  // *** Test ***//

  //  devUrl = 'http://10.250.20.190';
  //  baseUrl = 'http://10.250.20.190:8082';
  //  activeUrl = 'http://10.250.20.190/self-service';
  //  posSuccessCode = '02';





  // *** Production ***//

  // baseUrlPG = 'http://13.245.32.27';
  baseUrlPG = 'https://pay.sahwi.net';
    devUrl = 'https://selfservice.nyaradzo.co.zw';
  	baseUrl = 'https://selfservice.nyaradzo.co.zw';
   activeUrl = 'https://selfservice.nyaradzo.co.zw';
   posSuccessCode = '00';

}

