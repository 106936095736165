import { Global } from 'src/app/global';
import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent,
  HttpResponse, HttpUserEvent, HttpErrorResponse} from '@angular/common/http';
import { Router } from '@angular/router';
import {AuthStorage} from './auth.storage';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

const TOKEN_HEADER_KEY = 'Authorization';


@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private authStorage: AuthStorage, private router: Router,  private notification: ToastrService, private global: Global) { }
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    let authReq = req;
    if (this.authStorage.getToken() != null) {
      authReq = req.clone({ headers: req.headers
        .set(TOKEN_HEADER_KEY, 'Bearer ' + this.authStorage.getToken())
    });

    }
    return next.handle(authReq).pipe(
        catchError((err) => {
        console.log(err);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.status === 403) {
              this.notification.error(
                'Access Denied, Incorrect Username/Password',
                'Unathorized Access',
			  );
			//  this.authStorage.logOut();
			}else if ( err.status === 0 ) {
				this.notification.error(
				  'Error Encountered',
				  '',
				);
			//	this.authStorage.logOut();
			//	window.location.href=this.global.activeUrl;
			  }
			 else if (err.status === 400) {
              this.notification.error(
                'Bad Request',
                'Error: Encounted a Bad Request',
              );
            } else if (err.status === 404) {
              this.notification.error(
                'Error 404',
                'Resource not found',
              );
            } else if (err.status === 500) {
				this.notification.error(
					'Server Error 500',
					'Error: Internal Server Error',
				  );
            } else {

                console.log('Err Unknown');
            }
            return throwError(err);
          }
        })
      );

  }

}
