import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Global } from '../global';

@Injectable({
  providedIn: 'root'
})
export class CrudService implements BaseService {

  private baseUrl = this.global.baseUrl + '/v1/api';
  private baseUrlPG = this.global.baseUrlPG + '/v1/api/ns/payment';
  constructor(private http: HttpClient, private global: Global) { }
	postEasipol(t: any, url: string): Observable<any> {
		return this.http.post<any>(url, t);
	}

  save(t: any, url: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + url, t);
  }
  savePg(t: any, url: string): Observable<any> {
    return this.http.post<any>(this.baseUrlPG + url, t);
  }
  getAnyPg(url: string): Observable<any> {
    return this.http.get<any>(this.baseUrlPG + url);
  }
  postPosTransaction(t: any, url: string): Observable<any> {
    return this.http.post<any>(url, t);
  }
  postAny(t: any, url: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + url, t);
  }
  getAny(url: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + url);
  }
  getList(url: string): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + url);
  }
  getPagination(url: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + url);
  }
  delete(url: string): Observable<any> {
    return this.http.delete(this.baseUrl + url);
  }
  saveAlterationFile(file: any, url: string, id: string, dir: string, alterationType: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
	formData.append('id', id);
	formData.append('dir', dir);
	formData.append('alterationType', alterationType);
    return this.http.post<any>(this.baseUrl + url, formData);
  }


}
