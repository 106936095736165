

	<div class="outerDiv">
    <button routerLink="/auth/login" [disabled]="false"  class="btn btn-link"><i class="fa fa-arrow-left"></i></button>

			<h4 class="text-center">Are you an existing <b>Nyaradzo</b> client?</h4>
			<div style="margin-top: 20px;" class="confirm-btns">
				<button routerLink="/auth/register_client"   class="btn btn-primary">Yes</button>&nbsp;
				<button routerLink="/auth/register"  class="btn btn-secondary">No</button>
			</div>
		</div>


