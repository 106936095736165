import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CrudService } from 'src/app/core/crud.service';

@Component({
  selector: 'app-status-update',
  templateUrl: './status-update.component.html',
  styleUrls: ['./status-update.component.scss']
})
export class StatusUpdateComponent implements OnInit {

  @Input() status;
  @Input() id;
  txn: any;
  loading = false;
  amount = 0;
  constructor(private service: CrudService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
   // if(this.status === 'APPROVED'){
        this.getTxn();
   // }
  }

  getTxn(){
    this.loading = true;
		this.service.getAny('/payments/zim-switch-mastercard/p/update-txn/' + this.id + "/" + this.status).subscribe(
			result => {
		   console.log(result);
       this.amount = result.amount;
			 this.cdr.markForCheck();
		});
  }

}
