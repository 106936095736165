import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'kt-download-form-dialog',
  templateUrl: './download-form-dialog.component.html',
  styleUrls: ['./download-form-dialog.component.scss']
})
export class DownloadFormDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DownloadFormDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }
  downloadPdf(pdfUrl: string, pdfName: string ) {
    FileSaver.saveAs(pdfUrl, pdfName);
  }

}
