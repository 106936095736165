import { JwtTokenResponse } from './../../../../core/model/token.response';
import { AuthService } from './../../../../core/auth.service';
import { AuthStorage } from './../../../../core/auth.storage';
import { Component, EventEmitter, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  @Output() sendLoginForm = new EventEmitter<void>();
  public form: FormGroup;
  public flatlogicEmail = 'admin@flatlogic.com';
  public flatlogicPassword = 'admin';
  loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	jwtTokenResponse: JwtTokenResponse;

  constructor(
    private auth: AuthService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private router: Router,
		private authStorage: AuthStorage
	) {
	}

  public ngOnInit(): void {
    this.loginForm = this.fb.group({
			userName: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			]
		});
  }

  public login(): void {
    if (this.form.valid) {
      this.sendLoginForm.emit();
    }
  }

  navigate(link) {
		this.router.navigate([link]);
  }
  /**
	 * Form Submit
	 */
	submit() {
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const authData = {
			userName: controls.userName.value,
			password: controls.password.value
		};
		this.auth.login(authData.userName, authData.password).subscribe(
			result => {
				this.jwtTokenResponse = result;
				this.loading = false;
				if ( this.jwtTokenResponse) {
					this.authStorage.saveToken( this.jwtTokenResponse.token);
					this.authStorage.saveUser(JSON.stringify(this.jwtTokenResponse.customer));
					this.router.navigate(['/dashboard']);
				}

		},
		error => {
			this.loading = false;
			this.cdr.markForCheck();
		},
		() => {
		        this.loading = false;
				      this.cdr.markForCheck();
		}
    );
  }
  /**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
