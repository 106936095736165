// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';
import { CrudService } from 'src/app/core/crud.service';
import { MessageAlert } from 'src/app/core/model/message.alert';

@Component({
	selector: 'kt-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	// Public params
	forgotPasswordForm: FormGroup;
	loading = false;
	errors: any = [];
  messageAlert: MessageAlert = {viewMsg: false, msg: '', type: 'info'};

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authService
	 * @param authNoticeService
	 * @param translate
	 * @param router
	 * @param fb
	 * @param cdr
	 */
	constructor(
		private authService: AuthService,
		private router: Router,
		private fb: FormBuilder,
		private service: CrudService,
		private cdr: ChangeDetectorRef
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegistrationForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegistrationForm() {
		this.forgotPasswordForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		this.messageAlert = {viewMsg: false, msg: '', type: 'info'};
		const controls = this.forgotPasswordForm.controls;
		/** check form */
		if (this.forgotPasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;
  this.service.getAny('/customer/forgot-password/' + this.forgotPasswordForm.get('email').value).subscribe(
			result => {
       this.loading = false;
      //  console.log(result);

			 if (result === null) {
        this.messageAlert = {viewMsg: true, msg: 'Your email is not found!!', type: 'danger'};
			 } else if(result !== 'FAIL'){
				 const msg = 'A reset link has been sent to ' + this.forgotPasswordForm.get('email').value + '. Please open your email account and check your inbox or junk folder.';
        this.messageAlert = {viewMsg: true, msg: msg, type: 'success'};
			 } else{
        const msg = 'Failed to send reset link to your email.';
        this.messageAlert = {viewMsg: true, msg: msg, type: 'warning'};
       }
			},
			error => {
				this.loading = false;
			},
			() => {
				this.loading = false;
				this.cdr.markForCheck();
			}
		);

	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.forgotPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}
}
